<template>
    <div class="h-full p-2 rounded-lg" :class="colorHoraSeleccionado" @click="clicked">
        <div class="grid grid-rows-5 h-full">
            <div class="row-span-1 pt-1 justify-self-end  animate-bounce text-white text-sm">
                {{ instruction }}
            </div>
            <div class="row-span-3 text-white self-center pt-2" :class="faded">
                {{ dateTimeSeleccionado }}
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import { obtenerRandomEntero } from '@/utils/helpers';

export default {
    name: 'RelojDigital',
    setup() {
        const formatosHora = [
            'h:mm:ss A',
            'H:mm:ss'
        ];

        const coloresHoraYFecha = [
            'bg-blue-600',
            'bg-red-600',
            'bg-green-600',
            'bg-purple-700',
            'bg-pink-500',
            'bg-yellow-600',
            'bg-indigo-500',
            'bg-orange-600',
            'bg-gray-700'
        ]

        return {
            formatosHora,
            coloresHoraYFecha
        }
    },
    data() {
        return {
            dateTimeSeleccionado: moment().format(this.formatosHora[0]),
            numeroFormato: 0,
            numeroColorHora: 0,
            faded: "",
            instruction: "Clic",
        }
	},
	methods: {
		clicked: function () {
            this.faded = "faded";
            this.cambiarFormatoDateTime();
            this.cambiarColorHora();
            
            setTimeout(() => {
					this.faded = "";
                    this.dateTimeSeleccionado = moment().format(this.formatosHora[this.numeroFormato]);
				}, 200);
		},
        cambiarFormatoDateTime() {
            if (this.numeroFormato == this.formatosHora.length - 1) {
                this.numeroFormato = 0;
            }
            else{
                this.numeroFormato++;
            }
        },
        cambiarColorHora() {
            if (this.numeroColorHora == this.coloresHoraYFecha.length - 1) {
                this.numeroColorHora = 0;
            }
            else{
                this.numeroColorHora++;
            }
        },
        elegirNumeroColorAleatorio() {
            let numeroRandom = obtenerRandomEntero(0, this.coloresHoraYFecha.length);
            this.numeroColorHora = numeroRandom;
        }
	},
    computed: {
        fotmatoSeleccionado: function() {
            return this.formatosHora[this.numeroFormato];
        },
        colorHoraSeleccionado: function() {
            return this.coloresHoraYFecha[this.numeroColorHora];
        }
    },
	mounted: function () {
        moment.locale('es');
        this.elegirNumeroColorAleatorio();

		setInterval(() => {
			this.dateTimeSeleccionado = moment().format(this.formatosHora[this.numeroFormato]);
		}, 1000);
	}
}
</script>

<style>
    .faded {
        opacity: 0;
        transition: 0.2s;
    }
</style>