<template>
    <div class="bg-blue-50 px-6 z-40">
        <div class="grid grid-cols-12 xl:grid-rows-3 gap-3">
            <div class="bg-white rounded-lg shadow-md p-6 col-span-12 md:col-span-6 xl:row-span-1">
                <FrasesInspiradoras
                    :usuarioLogeado="this.usuarioLogeado"
                />
            </div>
            <div class="bg-white rounded-lg shadow-md p-2 col-span-12 md:col-span-6 xl:row-span-2">
                <div class="grid md:grid-rows-6 h-full">
                    <div class="grid row-span-5 pb-2">
                        <div class="w-56 h-56 sm:w-64 sm:h-64 md:w-72 md:h-72 xl:w-84 xl:h-84 
                        2xl:w-96 2xl:h-96 justify-self-center self-center pb-2">
                            <RelojAnalogo/>
                        </div>
                    </div>
                    <div class="flex row-span-1 justify-center self-start">
                        <div class="w-64 md:w-72 xl:w-84 xl:text-md 2xl:text-xl">
                            <TextoReloj/>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div class="col-span-12 xl:col-span-6 xl:row-span-1 bg-white rounded-lg shadow-md p-2">
                <RelojDigital class="text-4xl sm:text-5xl md:text-6xl lg:7xl xl:text-7xl"/>
            </div>
        </div>
    </div>
</template>

<script>
import RelojDigital from '@/components/reloj/RelojDigital';
import TextoReloj from '@/components/reloj/TextoReloj';
import RelojAnalogo from '@/components/reloj/RelojAnalogo';
import FrasesInspiradoras from '@/components/decoraciones/FrasesInspiradoras';
import { useStore } from 'vuex';
import { computed } from 'vue';

export default {
    name: 'Home',
    setup() {
      const store = useStore();

      return {
        usuarioLogeado: computed(() => store.getters["auth/authUser"])
      }
    },
    components: {
        FrasesInspiradoras,
        TextoReloj, 
        RelojDigital,
        RelojAnalogo
    }
}
</script>