<template>
    <div class="grid grid-rows-5 md:grid-rows-6 h-full">
        <div class="row-span-1 text-lg 2xl:text-xl text-left px-4 text-gray-800 font-semibold">
            ¡Hola de nuevo, {{ nombreUsuario }}! 
            <span class="text-gray-900 font-normal italic text-md 2xl:text-xl">Recuerda que...</span> 
        </div>
        <div class="row-span-4 text-lg  2xl:text-2xl text-left self-center font-semiboldbold italic px-5 text-transparent 
        bg-gradient-to-r bg-clip-text from-indigo-700 to-pink-600">
            {{ fraseElegida }}
        </div>
        <div class="row-span-1 self-end">
            <div class="text-right text-xs text-gray-500">Laboratorio de Camilo</div>
        </div>
    </div>
</template>

<script>
import { obtenerRandomEntero } from '@/utils/helpers';

export default {
    name: 'FrasesInspiradoras',
    setup() {
        const frases = [
            '"...la mejor manera de resolver los problemas es trabajando en equipo..."',
            '"...antes que elegir tener la razón es mejor elegir ser amable..."',
            '"...el resultado de tu trabajo a veces es incierto, pero tu actitud será siempre una certeza..."',
            '"...lo que determina tu altitud no es tu aptitud, sino tu actitud..."',
            '"...trabajar en lo correcto a veces es más importante que trabajar duro..."',
            '"...la fuerza del equipo es cada miembro individual. La fuerza de cada miembro es el equipo..."',
            '"...el hombre que mueve montañas comienza cargando pequeñas piedras..."',
            '"...cuando pierdas, no pierdas la lección..."',
            '"...para liderar a la gente, debes caminar tras ellos..."',
            '"...equivocarte te da la oportunidad de comenzar de nuevo y hacer las cosas mejor..."',
            '"...el trabajo se hace más corto cuando lo haces de buena gana..."',
            '"...la mayoría de las veces se aprende más de los errores que de los aciertos..."',
            '"...para dejar de fallar, tendrás que fallar muchas veces..."',
            '"...el que trabaja mal trabaja dos veces..."',
            '"...los verdaderos equipos comparten la carga y dividen el dolor..."',
            '"...muchos pasos en falso se han dado por quedarse quieto..."',
            '"...un líder es alguien que conoce el camino, anda el camino, y muestra el camino..."',
            '"...el que nunca ha aprendido a obedecer no puede ser un buen comandante..."',
            '"...antes que corregir a los demás es mejor dar el ejemplo..."',
            '"...una gran persona atrae a grandes personas y sabe cómo mantenerlas juntas ..."',
            '"...los patos salvajes siguen al líder de su parvada por la forma de su vuelo y no por la fuerza de su graznido..."',
            '"...un buen compañero celebra los aciertos, y apoya en los errores..."',
            '"...los consejos bien dados jamás hieren sentimientos..."',
            '"...la paciencia es la clave del éxito..."',
            '"...juntarse es un comienzo. Seguir juntos es un progreso. Trabajar juntos es un éxito..."',
            '"...se aprende más de alguien que piensa distinto a ti..."',
            '"...no debes juzgar a las personas por como te tratan a ti, sino por cómo tratan a todos..."',
            '"...el respeto es una calle de doble sentido, si quieres tenerlo, tienes que darlo..."',
            '"...ni el oro ni los diamantes se minan solos..."',
            '"...la paz solo se puede conseguir a través de la comprensión..."',
            '"...sin malos tratos, pasamos buenos ratos..."',
            '"...solos, somos ingredientes. Juntos, somos la obra maestra del chef..."',
            '"...ningún acto de amabilidad, aunque sea pequeño, es en vano..."',
            '"...una media verdad es una mentira completa..."',
            '"...tolerar solo a aquellos que están de acuerdo con nosotros no es tolerancia en lo absoluto..."',
            '"...tenemos dos oídos y una boca, para escuchar el doble de lo que hablamos..."',
            '"...nos debemos reir juntos, no del resto..."',
            '"...las palabras pueden entregar caricias o provocar cicatrices..."',
            '"...no importa los logros que hayas conseguido, siempre alguien te va a haber ayudado en algo..."',
            '"...cuando nos equivocamos con alguien debemos pedir disculpas..."',
            '"...dar el ejemplo no es la principal manera de influir en los demás. Es la única manera..."',
            '"...el trabajo en equipo es la habilidad de trabajar como un grupo hacia una visión común, incluso si la visión se vuelve extremadamente borrosa..."',
            '"...el primero en pedir disculpas es el más valiente. El primero en perdonar es el más fuerte. El primero en olvidar es el más feliz..."',
            '"...nadie es perfecto, por eso los lápices tienen goma..."',
            '"...tan poderosa es la luz de la unidad que puede iluminar la tierra entera..."',
            '"...la paz no es la ausencia de conflictos. Es la capacidad para manejar el conflicto por medios pacíficos..."',
            '"...nunca nada se pierde por cortesía. Es el más asequible de los placeres, no cuesta nada, y expresa mucho..."',
            '"...solos, podemos hacer poco. Juntos, podemos hacer mucho..."',
            '"...debes ignorar todo lo que alguien te diga de otra persona y juzgar a cada quien por tu propia cuenta..."',
            '"...a veces es mejor cerrar la boca y parecer idiota, que abrir la boca y disipar toda duda..."',
            '"...no consigues equilibrio cuando todo el mundo canta la misma nota..."',
            '"...es de valientes reconocer que algo no entiendes..."',
            '"...siendo osado es como se aprende a ser prudente..."',
            '"...es increíble lo que se puede conseguir cuando a nadie le importa quién se lleva el crédito..."',
            '"...es mil veces mejor pedir permiso que tener que pedir disculpas luego..."',
            '"...cuando decimos una mentira, le robamos a alguien el derecho a la verdad..."',
            '"...es mucho más recompensante llegar a la cima de la montaña y compartir tu experiencia con otros que mostrarte tú solo exhausto..."',
            '"...un bote no va hacia delante si cada uno rema en su propia dirección..."',
            '"...cuando las arañas tejen juntas, pueden atar a un león..."',
            '"...ninguno de nosotros es tan listo como todos nosotros..."',
            '"...individualmente, somos una gota. Juntos, somos un océano..."',
            '"...el trabajo en equipo es el secreto que hace que gente común consiga resultados poco comunes..."',
            '"...en el mundo de los negocios, las cosas importantes no son hechas por una sola persona. Son hechas por un grupo de personas..."'
            ];

        return {
            frases
        }
    },
    data() {
        return {
            numeroFreseSeleccionada: 0
        }
    },
    computed: {
        fraseElegida: function() {
            return this.frases[this.numeroFreseSeleccionada];
        },
        nombreUsuario: function() {
            let divisonesNombre = this.usuarioLogeado.name.split(" ", 1);
            
            return divisonesNombre[0];
        }
    },
    mounted: function () {
        let numeroRandom = obtenerRandomEntero(0, this.frases.length);
        this.numeroFreseSeleccionada = numeroRandom;

		setInterval(() => {
            if (this.numeroFreseSeleccionada == this.frases.length - 1) {
                this.numeroFreseSeleccionada = 0;
            }
            else{
                this.numeroFreseSeleccionada++;
            }
		}, 3600000);
	},
    props: {
        usuarioLogeado: {
            type: String,
            default: ''
        }
    }
}
</script>