<template>
  <div class="m-auto">
    <svg class="circle" viewBox="0 0 120 120" version="1.1"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="60" cy="60" r="60" :class="temaSeleccionado.relleno" class="ease-in-out duration-500"
      @click="clicked"
      />
      
      <line x1="60" y1="32" x2="60" y2="60" 
      class="hours origin-center transition ease-in-out duration-500 stroke-4" 
      :class="temaSeleccionado.manecillaHora"
      :style="transformHoras"
      @click="clicked"
      />
      <line x1="60" y1="15" x2="60" y2="60" 
      class="minutes origin-center transition ease-in-out duration-500 stroke-4" 
      :class="temaSeleccionado.manecillaMinuto"
      :style="transformMinutos"
      @click="clicked"
      />
      <line x1="60" y1="10" x2="60" y2="60" 
      class="seconds origin-center stroke-1" 
      :class="temaSeleccionado.manecillaSegundo"
      :style="transformSegundos"
      @click="clicked"
      />

      <line 
      v-for="indice in 12" :key="indice" 
      x1="60" y1="2" x2="60" y2="7" class="line origin-center stroke-1"
      :class="temaSeleccionado.lineaMinuto" 
      :style="transformLinea[indice]"
      @click="clicked"
      />

      <circle cx="60" cy="60" r="5" :class="temaSeleccionado.circuloCentral" 
      class="ease-in-out duration-500"
      @click="clicked"
      />

      <text x="57" y="61" style="font-size: 4px" fill="white" @click="clicked">
      Clic
      </text>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'RelojAnalogo',
  setup() {
    const temasReloj = [
      // Tema púrpura
      {
        relleno: 'fill-current text-purple-800',
        manecillaHora: 'stroke-current text-gray-900',
        manecillaMinuto: 'stroke-current text-gray-900',
        manecillaSegundo: 'stroke-current text-white',
        lineaMinuto: 'stroke-current text-white',
        circuloCentral: 'fill-current text-gray-900'
      },
      // Tema rosa
      {
        relleno: 'fill-current text-pink-500',
        manecillaHora: 'stroke-current text-pink-900',
        manecillaMinuto: 'stroke-current text-pink-900',
        manecillaSegundo: 'stroke-current text-white',
        lineaMinuto: 'stroke-current text-white',
        circuloCentral: 'fill-current text-pink-900'
      },
      // Tema gris
      {
        relleno: 'fill-current text-gray-700',
        manecillaHora: 'stroke-current text-gray-900',
        manecillaMinuto: 'stroke-current text-gray-900',
        manecillaSegundo: 'stroke-current text-white',
        lineaMinuto: 'stroke-current text-white',
        circuloCentral: 'fill-current text-gray-900'
      }
    ]

    return {
      temasReloj
    }
  },
  data() {
    return {
      transformHoras: {
        transform: ''
      },
      transformMinutos: {
        transform: ''
      },
      transformSegundos: {
        transform: ''
      },
      transformLinea: [
        {transform: ''},
        {transform: ''},
        {transform: ''},
        {transform: ''},
        {transform: ''},
        {transform: ''},
        {transform: ''},
        {transform: ''},
        {transform: ''},
        {transform: ''},
        {transform: ''},
        {transform: ''}
      ],
      numeroTemaSeleccionado: 0
    }
  },
  methods: {
    linearMap(value, min, max, newMin, newMax) {
      return newMin + (newMax - newMin) * (value - min) / (max - min);
    },
    calculateHourDegrees() {
      const currentHour = new Date().getHours() - 12;
      const angle = this.linearMap(currentHour, 0, 12, 0, 360);
      this.transformHoras.transform = `rotate(${angle}deg)`;
    },
    calculateMinuteDegrees() {
      const currentMinutes = new Date().getMinutes();
      const angle = this.linearMap(currentMinutes, 0, 60, 0, 360);
      this.transformMinutos.transform = `rotate(${angle}deg)`;
    },
    calculateSeconds() {
      const currentMinutes = new Date().getSeconds();
      const angle = this.linearMap(currentMinutes, 0, 60, 0, 360);
      this.transformSegundos.transform = `rotate(${angle}deg)`;
    },
    calculateLines() {
      const numberLines = 12;

      for (let i = 0; i < numberLines; i++) {
        const angle = this.linearMap(i, 0, numberLines, 0, 360);
        this.transformLinea[i].transform = `rotate(${angle}deg)`;
      }
    },
    actualizarHora() {
      this.calculateHourDegrees();
      this.calculateMinuteDegrees();
      this.calculateSeconds();
    },
    clicked: function () {
      if (this.numeroTemaSeleccionado == this.temasReloj.length - 1) {
        this.numeroTemaSeleccionado = 0;
      }
      else{
        this.numeroTemaSeleccionado++;
      }
		},
  },
  computed: {
    temaSeleccionado: function() {
      return this.temasReloj[this.numeroTemaSeleccionado];
    }
  },
  mounted() {
    this.calculateLines();
    this.actualizarHora();

    setInterval(() => {
      this.actualizarHora();
    }, 1000);
  }
}
</script>

<style>
  .hours,
  .minutes,
  .seconds {
    stroke-linecap: round;
  }
  .line {
    stroke-linecap: round;
  }
</style>