<template>
    <div class="inline-flex h-full w-full">
        <div class="inline-flex text-white uppercase bg-gray-500 px-3 rounded-l-lg">
            <div class="pt-5">{{ meridiem }}</div>
            <div class="pt-5">m</div>
        </div>

        <div class="grid grid-rows-2 border border-gray-500 rounded-r-lg w-full">
            <div v-for="(part, i) in output" :key="i" class="w-full pt-2 pl-2 text-left uppercase">{{ part }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TextoReloj',
    data() {
        return {
            timeMap: {
                20: 'veinti',
                30: 'treintai',
                40: 'cuarentai',
                50: 'cincuentai',
                0: [null, 'uno', 'dos', 'tres', 'cuatro', 'cinco', 'seis', 'siete', 'ocho', 'nueve', 'diez'],
                10: [null, 'once', 'doce', 'trece', 'catorce', 'cuarto', 'dieciseis', 'diecisiete', 'dieciocho', 'diecinueve', 'veinte'],
                hours: [null, 'una', 'dos', 'tres', 'cuatro', 'cinco', 'seis', 'siete', 'ocho', 'nueve', 'diez', 'once', 'doce']
            },
            parsers: [
                {
                    test: n => !n,
                    parse: () => ({ m: `en punto` })
                },
                {
                    test: n => n <= 10,
                    parse: n => ({ m: this.timeMap[0][n] })
                },
                {
                    test: n => ~[15, 30].indexOf(n),
                    parse: n => ({ m: n === 30 ? 'y media' : 'un cuarto' })
                },
                {
                    test: n => ~[40, 45].indexOf(n),
                    parse: n => ({ r: 1, m: `${n === 45 ? 'un cuarto' : this.timeMap[10][10] } para las` })
                },
                {
                    test: n => ~[35, 50, 55].indexOf(n),
                    parse: n => ({ r: 1, m: `${n === 35 ? `${this.timeMap[20]}${this.timeMap[0][5]}`: this.timeMap[0][60 - n] } para las` })
                },
                {
                    test: n => n <= 20,
                    parse: n => ({ m: this.timeMap[10][n - 10]})
                },
                {
                    test: n => n,
                    parse: n => ({ m: `${this.timeMap[(n * 0.1 | 0) * 10]}${this.timeMap[0][n % 10]}` })
                }
            ],
            time: { 
                hours: 0, 
                minutes: 0, 
                seconds: 0 
            }
        }
    },
    methods: {
        updateTime(time) {
            this.tick++;
            this.time = {
                hours: time.getHours(),
                minutes: time.getMinutes(),
                seconds: time.getSeconds()
            }

            setTimeout(() => this.updateTime(new Date()), 1000 - new Date().getMilliseconds());
        }
    },
    computed: {
        meridiem() {
            return (this.time.hours < 12) ? 'a' : 'p'
        },
        output() {
            const { hours: h, minutes: m } = this.time;
            const { r: reverse, m: minutes } = this.parsers
                .find(({ test }) => test(m))
                .parse(m)

        const hours = [h]
            .map(h => h > 12 ? h - 12 : h || 12)
            .map(h => /\spara las$/.test(minutes) ? h + 1 : h)
            .map(h => h > 12 ? 1 : h)
            .map(h => this.timeMap.hours[h])
            .reduce(h => h)

        return reverse ? [minutes, hours] : [hours, minutes]
        }
    },
    mounted() {
        this.updateTime(new Date());
    }
}
</script>